import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';

import CardRewards from '../card-rewards';
import PersonalInformation from '../personal-information';
import SecurityInformation from '../security-information';
import MailingList from '../mailing-list';
import TermsAndConditions from '../terms-and-conditions';

const AccountCreateColumn = ({
  cardRewardsProps,
  fields,
  isFetching,
  variation,
}) => (
  <div className="pt3 md-pt5">
    <PersonalInformation
      firstName={fields.firstName}
      lastName={fields.lastName}
    />

    <SecurityInformation
      emailAddress={fields.emailAddress}
      password={fields.password}
      variation={variation}
    />

    <CardRewards
      birthDay={fields.birthDay}
      birthMonth={fields.birthMonth}
      cardRewards={fields.cardRewards}
      cardRewardsProps={cardRewardsProps}
      registerCardNumber={fields.registerCardNumber}
      registerCardPin={fields.registerCardPin}
    />

    <MailingList
      receiveGeneralEmailCommunications={
        fields.receiveGeneralEmailCommunications
      }
      receivePersonalizedEmailCommunications={
        fields.receivePersonalizedEmailCommunications
      }
      receiveStarbucksEmailCommunications={
        fields.receiveStarbucksEmailCommunications
      }
    />

    <TermsAndConditions
      storageConsent={fields.storageConsent}
      termsAndConditions={fields.termsAndConditions}
    />

    <div className="flex justify-end mt6">
      <Frap data-e2e="createButton" loading={isFetching} type="submit">
        <FormattedMessage
          defaultMessage="Create account"
          description="Label for create account button"
          id="accountCreate.accountCreateColumn.createAccountButton"
        />
      </Frap>
    </div>
  </div>
);

AccountCreateColumn.propTypes = {
  cardRewardsProps: PropTypes.object,
  fields: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default AccountCreateColumn;
