import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import PasswordFieldWithValidation from 'shared/app/components/password-field-with-validation';

import { accountsMessages } from 'shared/app/messages';
import EmailField from 'shared/app/components/email-field';
import FormCard from '../form-card';

const messages = defineMessages({
  sectionHeading: {
    id: 'accountCreate.securityInformation.heading',
    defaultMessage: 'Account Security',
    description: 'Heading for account security section of account create form',
  },
  phoneNumberError: {
    id: 'accountCreate.securityInformation.error',
    defaultMessage: 'Please enter a 10-digit phone number.',
    description: 'Error message displayed when phone number is missing',
  },
});

const SecurityInformation = ({ emailAddress, password }) => (
  <FormCard>
    <Heading className="mb2 text-semibold" size="sm" tagName="h2">
      <FormattedMessage {...messages.sectionHeading} />
    </Heading>
    <EmailField className="mb2" field={emailAddress} />
    <PasswordFieldWithValidation
      field={password}
      fieldProps={{
        'data-e2e': 'password',
      }}
      label={<FormattedMessage {...accountsMessages.password} />}
    />
  </FormCard>
);

SecurityInformation.propTypes = {
  emailAddress: PropTypes.object,
  password: PropTypes.object,
};

export default SecurityInformation;
