import { defineMessages } from 'react-intl';

export default defineMessages({
  cardInformationError: {
    id: 'accountCreate.partialSuccess.cardInformationError',
    defaultMessage: 'Please try entering your card information again.',
    description: 'Error message displayed when card information is invalid.',
  },
  cardStateError: {
    id: 'accountCreate.partialSuccess.cardStateError',
    defaultMessage: 'Please try another card.',
    description: 'Error message displayed when card is invalid.',
  },
  cardManagementLink: {
    id: 'accountCreate.partialSuccess.cardManagementLink',
    description: 'Link to card management page',
    defaultMessage: 'Register a card',
  },
  accountDashboardLink: {
    id: 'accountCreate.partialSuccess.accountDashboardLink',
    description: 'Link to account dashboard page',
    defaultMessage: 'Go to dashboard',
  },
});
