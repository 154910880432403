import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import LegacyPaddedContentColumn from 'shared/app/components/legacy-padded-content-column';
import {
  cardErrorMessageIdSelector,
  successRedirectUrlSelector,
  cardRegistrationUrlSelector,
} from '../../state/selectors';

import messages from './messages';

export const PartialSuccess = ({
  messageId,
  cardRegistrationUrl,
  successRedirectUrl,
}) => {
  return (
    <LegacyPaddedContentColumn size="sm">
      <p className="mb3">
        <FormattedMessage {...messages[messageId]} />
      </p>
      <Button className="my4" href={cardRegistrationUrl} visualStyle="positive">
        <FormattedMessage {...messages.cardManagementLink} />
      </Button>
      <span className="block">
        <Button href={successRedirectUrl}>
          <FormattedMessage {...messages.accountDashboardLink} />
        </Button>
      </span>
    </LegacyPaddedContentColumn>
  );
};

PartialSuccess.propTypes = {
  messageId: PropTypes.string.isRequired,
};

const select = (state) => ({
  messageId: cardErrorMessageIdSelector(state),
  successRedirectUrl: successRedirectUrlSelector(state),
  cardRegistrationUrl: cardRegistrationUrlSelector(state),
});

export default connect(select)(PartialSuccess);
