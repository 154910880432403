import { defineMessages } from 'react-intl';

export default defineMessages({
  expanderLabel: {
    id: 'accountCreate.cardRewardsExpander.expanderLabel',
    defaultMessage: 'Already have a Starbucks gift card?',
    description: 'Label for expander that shows register physical card fields',
  },
  expanderInstructions: {
    id: 'accountCreate.cardRewardsExpander.expanderInstructions',
    defaultMessage:
      'Add your gift card to earn Stars when you pay and order ahead.',
    description: 'Copy inside expander for register physical card fields',
  },
});
