import { findKey } from 'lodash';

// mapping between route parameters and messageIds.
const messageMap = {
  cardInformationError: 'card-information-error',
  cardStateError: 'card-state-error',
};

export const toMessageId = (routeParam) =>
  findKey(messageMap, (v) => v === routeParam);

export const toRouteParam = (messageId) => messageMap[messageId];
