import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const FormCard = (props) => {
  const { className, ...otherProps } = props;
  const classList = classNames('pb6', {
    [className]: Boolean(className),
  });

  return <fieldset className={classList} {...otherProps} />;
};

FormCard.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.object,
};

export default FormCard;
