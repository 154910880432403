import AccountCreateMain from './components/root';

import PartialSuccess from './components/partial-success';
import PartialSuccessHeader from './components/partial-success-header';
import { SIGNAL_CREATE_ACCOUNT } from './actions';

export default {
  name: 'accountCreate',
  routes: {
    '/account/create': {
      fullPageComponent: AccountCreateMain,
    },
    '/account/create/partial-success/:message': {
      headerCrate: PartialSuccessHeader,
      contentCrate: PartialSuccess,
      hideGlobalNav: true,
    },
  },
  asyncActions: [SIGNAL_CREATE_ACCOUNT],
};
