import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cardNumberError: {
    id: 'accountCreate.cardRewards.cardNumberError',
    defaultMessage: 'A valid Starbucks card number has 16 digits.',
    description: 'Error message displayed when card number is invalid',
  },
  cardNumberInstructions: {
    id: 'accountCreate.cardRewards.cardNumberInstructions',
    defaultMessage: 'No spaces or dashes',
    description: 'Label explaining the credit card format',
  },
  cardNumberLabel: {
    id: 'accountCreate.cardRewards.cardNumberLabel',
    defaultMessage: 'Card number (16 digits)',
    description: 'Label for account create card number field',
  },
  cardPinError: {
    id: 'accountCreate.cardRewards.cardPinError',
    defaultMessage: 'A valid security code has 8 digits.',
    description: 'Error message displayed when card pin is invalid',
  },
  cardPinInstructions: {
    id: 'accountCreate.cardRewards.cardPinInstructions',
    defaultMessage: 'Scratch to reveal 8-digit code',
    description: 'Label explaining how to find card pin',
  },
  cardPinLabel: {
    id: 'accountCreate.cardRewards.cardPinLabel',
    defaultMessage: 'Security code',
    description: 'Label for account create card pin field',
  },
});
