import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Checkbox from '@starbucks-web/pattern-library/lib/components/checkbox';
import Expander from '@starbucks-web/pattern-library/lib/components/expander';
import FormHeading from '@starbucks-web/pattern-library/lib/components/form-heading';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import ExternalLink from 'shared/app/components/external-link';
import {
  cardTermsAndConditionsUrlSelector,
  privacyStatementUrlSelector,
  rewardsTermsOfUseUrlSelector,
} from 'shared/app/state/selectors/privacy-and-terms';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { accountsMessages } from 'shared/app/messages';

import { starbucksRewardsTrademarkMessage } from 'shared/app/components/starbucks-rewards-trademark';
import FormCard from '../form-card';
import messages from './messages';

export const TermsAndConditions = ({
  cardTermsAndConditionsUrl,
  localeTag,
  privacyStatementUrl,
  rewardsTermsOfUseUrl,
  storageConsent,
  termsAndConditions,
}) => {
  const displayStorageConsent = Boolean(storageConsent);
  const { formatMessage } = useIntl();
  const isENUSlocale = localeTag === 'en-US';

  const cardTerms = (
    <ExternalLink data-e2e="cardTerms" href={cardTermsAndConditionsUrl}>
      {formatMessage(messages.cardTermsName)}
    </ExternalLink>
  );
  const privacyStatement = (
    <ExternalLink
      data-e2e="privacyStatement"
      href={
        isENUSlocale
          ? `${privacyStatementUrl}#notice-of-financial-incentive`
          : privacyStatementUrl
      }
    >
      {formatMessage(messages.privacyStatementName)}
    </ExternalLink>
  );
  const rewardsTerms = (
    <ExternalLink data-e2e="rewardsTerm" href={rewardsTermsOfUseUrl}>
      {formatMessage(messages.rewardsTermsName, {
        starbucksRewardsTrademark: formatMessage(
          starbucksRewardsTrademarkMessage
        ),
      })}
    </ExternalLink>
  );
  const StorageConsent = () => {
    return !displayStorageConsent ? null : (
      <div>
        <Checkbox
          {...storageConsent.input}
          checked={storageConsent?.input?.checked ?? false}
          data-e2e="storageConsent"
          error={shouldDisplayError(storageConsent)}
          errorMessage={formatMessage(messages.storageConsentError)}
        >
          <FormattedMessage {...messages.storageConsentMessage} />
        </Checkbox>
      </div>
    );
  };
  const Terms = () => (
    <div>
      <Checkbox
        {...termsAndConditions.input}
        checked={termsAndConditions?.input?.checked ?? false}
        data-e2e="tacCheckbox"
        error={shouldDisplayError(termsAndConditions)}
        errorMessage={formatMessage(messages.termsOfUseError)}
      >
        <span className="color-greenAccent">{`* `}</span>
        <FormattedMessage
          {...messages.termsCheckboxLabel}
          values={{ rewardsTerms, cardTerms, privacyStatement }}
        />
      </Checkbox>
    </div>
  );

  return (
    <div>
      <FormHeading className="mb4">
        <FormattedMessage {...accountsMessages.termsOfUse} />
      </FormHeading>
      <Terms />
      <Expander isExpanded={displayStorageConsent}>
        <FormCard>
          <StorageConsent />
        </FormCard>
      </Expander>
    </div>
  );
};

TermsAndConditions.propTypes = {
  storageConsent: PropTypes.object,
  termsAndConditions: PropTypes.object,
};

const select = (state) => ({
  cardTermsAndConditionsUrl: cardTermsAndConditionsUrlSelector(state),
  localeTag: localeTagSelector(state),
  privacyStatementUrl: privacyStatementUrlSelector(state),
  rewardsTermsOfUseUrl: rewardsTermsOfUseUrlSelector(state),
});

export default connect(select)(TermsAndConditions);
