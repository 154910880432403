import { composeApp } from 'shared/app/shell';
import accountCreate from './account-create-sub-app';
import GlobalNav from 'shared/app/components/global-nav';

export default composeApp({
  apps: [accountCreate],
  nav: {
    globalNav: {
      component: GlobalNav,
      props: {
        logoOnly: true,
      },
    },
  },
});
