import { defineMessages } from 'react-intl';

export default defineMessages({
  privacyStatement: {
    id: 'accountCreate.mailingList.privacyMessage',
    defaultMessage:
      'You can unsubscribe at any time. Please read our {privacyStatementName} or {privacyStatementContactUsName}.',
    description:
      'Message to inform user about privacy statement when creating an account',
  },
  privacyStatementName: {
    id: 'accountCreate.mailingList.privacyStatementName',
    defaultMessage: 'Privacy Statement',
    description: 'The name of the privacy statement',
  },
  privacyStatementContactUsName: {
    id: 'accountCreate.mailingList.privacyStatementContactUsName',
    defaultMessage: 'Contact Us',
    description: 'The label for contact us link in privacy statement',
  },
  cardTitle: {
    id: 'accountCreate.mailingList.title',
    defaultMessage: 'Collect more Stars & Earn Rewards',
    description:
      'Account create mailing list description heading when card is registered',
  },
  cardDescription: {
    id: 'accountCreate.mailingList.description',
    defaultMessage:
      'Email is a great way to know about offers and what’s new from Starbucks.',
    description:
      'Account create mailing list description when card is registered',
  },
  mailingListLabel: {
    id: 'accountCreate.mailingList.generalEmail.label',
    defaultMessage: 'Yes, I’d like email from Starbucks',
    description: 'Label for account create mailing list checkbox',
  },
  mailingListDescription: {
    id: 'accountCreate.mailingList.generalEmail.description',
    defaultMessage: 'Know about initiatives, announcements and product offers.',
    description: 'Label for account create mailing list checkbox description',
  },
  personalizedEmailLabel: {
    id: 'accountCreate.mailingList.personalizedEmail.label',
    defaultMessage:
      'Yes, I’d like Starbucks to send me email about initiatives, announcements and product offers based on my account info and activity.',
    description: 'Label for account create personalized mailing list checkbox',
  },
  privacyNoticeLinkText: {
    id: 'accountCreate.mailingList.privacyNoticeLink.text',
    defaultMessage: 'privacy policy',
    description: 'text of link to privacy policy notice',
  },
});
