import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import xhr from 'xhr';
import pify from 'pify';
import { Helmet } from 'react-helmet';
import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';
import FormWithMessaging from 'shared/app/components/form-with-messaging';
import { getFraudReputation } from 'shared/app/utils/iovation';
import { showUnexpectedErrorNotification } from 'shared/app/shell';
import { configBffBaseUrlSelector } from 'shared/app/state/selectors/config';
import {
  currentCountryCodeSelector,
  coreAppBaseUrlSelector,
  localeTagSelector,
} from 'shared/app/state/selectors/locales';
import { storeCredential } from 'shared/app/utils/credential-management';
import {
  CARD_INPUTS_KEY,
  getSessionStorageItem,
  removeSessionStorageItem,
} from 'shared/app/utils/session-storage';
import { localeEmailTopicsSelector } from 'shared/app/state/selectors/communication-preferences';

import AccountCreateColumn from '../account-create-column';
import { toRouteParam } from '../../utils/map-error-message';
import { messages } from '../../messages';

import {
  checkGeneralEmailByDefaultSelector,
  includeStorageConsentSelector,
  successRedirectUrlSelector,
} from '../../state/selectors';

import {
  signalCreateAccount,
  signalCreateAccountError,
} from '../../state/action-creators';

import {
  validateChecked,
  validateSvcCardNumber,
  validateSvcCardPin,
} from '../../utils/validators';
import getRedirectUrl from '../../utils/get-redirect-url';
import { validatePassword } from 'shared/app/components/password-field-with-validation/validator';

import { validateEmail, validateName } from 'shared/app/utils/validators';
import { parseXhrResponseOrThrow } from 'shared/app/utils/fetch-utils';
import {
  trackAuthCreateAccountClick,
  trackAuthCreateAccountError,
  trackAuthCreateAccountSuccess,
} from '../../state/track-event';
import loadTrackingPixel from 'shared/app/utils/load-tracking-pixel';
import setCommunicationPreferencesFields from '../communication-preferences/communication-preferences-fields';
import { getWindow } from 'shared/app/utils/window';
import { removeAllFlags } from 'shared/app/utils/local-storage-flags';
import { setTermsPrivacyFlag } from 'shared/app/utils/local-storage-privacy-flag';

const accountCreateRegistrationSource = 'Web';
const promisedXhr = pify(xhr);
let win;

export class AccountCreate extends Component {
  constructor(props) {
    super();
    this.postForm = this.postForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitError = this.handleSubmitError.bind(this);
    this.setServerFieldError = this.setServerFieldError.bind(this);
    this.handleFieldsStateChange = this.handleFieldsStateChange.bind(this);
    this.handleRegisterCardError = this.handleRegisterCardError.bind(this);
    this.handleCardRegistrationError =
      this.handleCardRegistrationError.bind(this);
    this.toggleCardRewardsExpander = this.toggleCardRewardsExpander.bind(this);
    this.initializeFieldConfig(props);

    this.state = {
      birthdayIsRequired: false,
      isFetching: false,
      physicalSvcFieldsExpanded: false,
      cardRewardsVal: 'digitalCard',
    };
  }

  handleRegisterCardError(invalidFields) {
    // Open expander to reveal errored registerCard fields
    if (invalidFields.registerCardNumber || invalidFields.registerCardPin) {
      this.setState({ physicalSvcFieldsExpanded: true });
    }
  }

  toggleCardRewardsExpander() {
    this.setState({
      physicalSvcFieldsExpanded: !this.state.physicalSvcFieldsExpanded,
    });
  }

  initializeCommunicationPreferencesFields(props) {
    return setCommunicationPreferencesFields(
      props.localeEmailTopics,
      props.checkGeneralEmailByDefault
    );
  }

  initializeFieldConfig(props) {
    const { includeStorageConsent } = props;

    const baseFields = {
      emailAddress: { validator: validateEmail },
      firstName: { validator: validateName },
      lastName: {
        validator: (lastNameValue) => validateName(lastNameValue, null, true),
      },
      password: { validator: validatePassword },
      termsAndConditions: { validator: validateChecked },
    };

    // Check config for what optional fields to include for this locale
    const optionalFields = Object.assign(
      {},
      includeStorageConsent
        ? {
            storageConsent: {
              validator: validateChecked,
            },
          }
        : {}
    );

    this.fields = Object.assign(
      {},
      this.initializeCommunicationPreferencesFields(props),
      baseFields,
      optionalFields
    );

    // returning to test function
    return this.fields;
  }

  getActiveFields() {
    // These fields depend on this.state/user interaction to get their value and can't be initialized in constructor
    const { cardRewardsVal } = this.state;

    const includeRegisterCard =
      cardRewardsVal === 'registerCard'
        ? {
            registerCardNumber: {
              validator: validateSvcCardNumber,
            },
            registerCardPin: {
              validator: validateSvcCardPin,
            },
          }
        : {};

    const cardRewards = {
      cardRewards: {
        input: {
          value: cardRewardsVal,
        },
      },
    };

    return Object.assign({}, this.fields, cardRewards, includeRegisterCard);
  }

  componentDidMount() {
    win = getWindow();

    removeAllFlags();
    const sessionCard = getSessionStorageItem(CARD_INPUTS_KEY);
    if (Object.keys(sessionCard).length > 0 && this.$formContainer) {
      this.$formContainer.updateField({
        input: {
          name: 'registerCardNumber',
          value: sessionCard.cardNumber,
        },
      });
      this.$formContainer.updateField({
        input: {
          name: 'registerCardPin',
          value: sessionCard.securityCode,
        },
      });
    }
  }

  componentDidUpdate() {
    // If grandchild CardRewardsExpander changes, change card rewards' field value
    if (this.$formContainer) {
      const { registerCardNumber, registerCardPin } =
        this.$formContainer?.state?.fields ?? {};

      const value =
        this.state.physicalSvcFieldsExpanded ||
        registerCardNumber?.input?.value ||
        registerCardPin?.input?.value
          ? 'registerCard'
          : 'digitalCard';
      this.$formContainer.updateField({
        input: {
          name: 'cardRewards',
          value,
        },
      });
    }
  }

  handleCardRegistrationError({ messageId }) {
    const routeParam = toRouteParam(messageId);
    win.location.href = `/account/create/partial-success/${routeParam}`;
  }

  setServerFieldError({ messageId, message, field }) {
    const { formatMessage } = this.props.intl;
    const errorMessage = messageId
      ? formatMessage(messages[messageId])
      : message;

    this.$formContainer.updateField({
      errorMessage,
      hasServerSideError: true,
      input: {
        name: field,
      },
    });
    this.$formContainer.focusOnFirstInvalid([field]);
  }

  handleFieldsStateChange(fieldsState, oldFieldsState) {
    const cardRewards = fieldsState.fields.cardRewards.input.value;
    const oldCardRewards = oldFieldsState?.fields?.cardRewards?.input?.value;

    if (cardRewards !== oldCardRewards) {
      this.setState({ cardRewardsVal: cardRewards });
    }

    if (
      fieldsState?.fieldsbirthMonth?.input?.value ||
      fieldsState?.fieldsbirthDay?.input?.value
    ) {
      this.setState({ birthdayIsRequired: true });
    }
  }

  // eslint-disable-next-line max-statements
  handleSubmitError(error) {
    const errorBody = error?.response?.body;
    this.props.signalCreateAccountError();
    this.setState({ isFetching: false });

    trackAuthCreateAccountError();

    if (
      !errorBody ||
      errorBody.type === 'reputation' ||
      errorBody.type === 'unknown-code'
    ) {
      return this.props.showUnexpectedErrorNotification();
    }

    if (errorBody.type === 'validation') {
      this.setServerFieldError(errorBody);
      return;
    }
    if (errorBody.type === 'card-registration') {
      setTermsPrivacyFlag();
      if (errorBody.cardType === 'digitalCard') {
        this.handleCardRegistrationError(errorBody);
        return;
      }
      const url = `${this.props.coreAppBaseUrl}/account/cards`;
      window.location.href = `${url}?mode=from_account_create&card_error=${errorBody.errorCode}`;
    }
  }

  postForm(data) {
    const { bffBaseUrl } = this.props;
    return promisedXhr({
      url: `${bffBaseUrl}/bff/account/create`,
      json: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(parseXhrResponseOrThrow);
  }

  handleSubmit(formData) {
    const { handleSubmitError } = this;
    const { emailAddress, password } = formData;

    this.props.signalCreateAccount();
    this.setState({ isFetching: true });
    removeSessionStorageItem(CARD_INPUTS_KEY);
    trackAuthCreateAccountClick();

    return getFraudReputation()
      .then((reputation) => {
        return Object.assign(
          {
            country: this.props.currentCountryCode,
            locale: this.props.currentLocale,
            registrationSource: accountCreateRegistrationSource,
          },
          { reputation },
          formData
        );
      })
      .then(this.postForm)
      .then((result) => {
        const userExId = result?.body?.userExId;
        setTermsPrivacyFlag();
        return Promise.all([
          storeCredential({
            password,
            username: emailAddress,
          }).catch(() => {
            // swallow error for edge case browsers
            // like Electron running in the Window Teams desktop app
          }),
          trackAuthCreateAccountSuccess(userExId),
          // The TradeDesk pixel helps track the cost per Starbucks Rewards customer acquisition.
          loadTrackingPixel(
            '//insight.adsrvr.org/track/conv/?adv=hki6w7l&ct=0:5aelfzrl&fmt=3&v=1'
          ),
          // The Marin pixel helps track the lifetime value of Starbucks Rewards customers.
          loadTrackingPixel('//tracker.marinsm.com/tp?act=2&cid=3944mk441822'),
        ]);
      })
      .then(() => {
        win.location.href = getRedirectUrl(this.props.successRedirectUrl);
      })
      .catch(handleSubmitError);
  }

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="sm-pt0 md-p8">
        <Helmet title={formatMessage(messages.pageTitle)} />
        <FormContainer
          fields={this.getActiveFields()}
          focusOnInvalidAfterAnimation
          focusOnSubmitError
          onError={this.handleRegisterCardError}
          onFieldsStateChange={this.handleFieldsStateChange}
          onSubmit={this.handleSubmit}
          ref={(el) => (this.$formContainer = el)}
        >
          <FormWithMessaging>
            <AccountCreateColumn
              cardRewardsProps={{
                physicalSvcFieldsExpanded: this.state.physicalSvcFieldsExpanded,
                toggleCardRewardsExpander: this.toggleCardRewardsExpander,
              }}
              isFetching={this.state.isFetching}
            />
          </FormWithMessaging>
        </FormContainer>
      </div>
    );
  }
}

const select = (state) => ({
  bffBaseUrl: configBffBaseUrlSelector(state),
  checkGeneralEmailByDefault: checkGeneralEmailByDefaultSelector(state),
  coreAppBaseUrl: coreAppBaseUrlSelector(state),
  currentCountryCode: currentCountryCodeSelector(state),
  currentLocale: localeTagSelector(state),
  includeStorageConsent: includeStorageConsentSelector(state),
  localeEmailTopics: localeEmailTopicsSelector(state),
  successRedirectUrl: successRedirectUrlSelector(state),
});

export default connect(select, {
  showUnexpectedErrorNotification,
  signalCreateAccount,
  signalCreateAccountError,
})(injectIntl(AccountCreate));
