import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import H1 from 'shared/app/components/h1';
import {
  isPromoRedeemRequestSelector,
  getPartnershipSelector,
} from 'shared/app/state/selectors/return-url-selectors';

import messages from './messages';
import PartnershipHeader from './partnership';

export const AccountCreateHeader = () => {
  const isPromoRedeemRequest = useSelector(isPromoRedeemRequestSelector);
  const partnership = useSelector(getPartnershipSelector);

  const currentHeaderMessage = isPromoRedeemRequest
    ? messages.createAccountPromoHeader
    : messages.createAccountStandardHeader;
  return (
    <div className="mb4 md-mb7">
      {partnership ? (
        <PartnershipHeader partnership={partnership} />
      ) : (
        <H1 className="text-bold text-center" size="lg">
          <FormattedMessage {...currentHeaderMessage} />
        </H1>
      )}
    </div>
  );
};

export default AccountCreateHeader;
