import {
  SIGNAL_CREATE_ACCOUNT,
  SIGNAL_CREATE_ACCOUNT_ERROR,
  SIGNAL_CREATE_ACCOUNT_SUCCESS,
} from '../actions';

export const signalCreateAccount = () => ({ type: SIGNAL_CREATE_ACCOUNT });
export const signalCreateAccountError = () => ({
  type: SIGNAL_CREATE_ACCOUNT_ERROR,
});
export const signalCreateAccountSuccess = () => ({
  type: SIGNAL_CREATE_ACCOUNT_SUCCESS,
});
