import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  rewardsInfo: {
    id: 'accountCreate.rewardsInfo',
    description: 'Starbucks rewards program info',
    defaultMessage:
      'Join Starbucks Rewards to earn Stars for free food and drinks, any way you pay. Get access to mobile ordering, a birthday Reward, and {more}.',
  },
  pageTitle: {
    id: 'accountCreate.pageTitle',
    defaultMessage: 'Create a Starbucks Account',
    description: 'Document title for account create page',
  },
  unavailableEmailError: {
    id: 'accountCreate.unavailableEmailError',
    defaultMessage: 'Email address is not available.',
    description: 'Error displayed when email address is unavailable',
  },
  plusMoreCTA: {
    id: 'accountCreate.rewardsInfo.moreCTA',
    description: 'Starbucks rewards program info more CTA',
    defaultMessage: 'more',
  },
  a11yContext: {
    id: 'accountCreate.rewardsInfo.moreA11yContext',
    description: 'Screen reader content for the more link',
    defaultMessage: 'Learn more about Starbucks rewards',
  },
});
