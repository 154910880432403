import React from 'react';
import { FormattedMessage } from 'react-intl';

import H1 from 'shared/app/components/h1';
import HeaderCrateWithLogo from 'shared/app/components/header-crate-with-logo';

export default () => (
  <HeaderCrateWithLogo>
    <H1 size="md">
      <FormattedMessage
        defaultMessage="Your account has been created, however there was an error with your card."
        description="Heading for partial success page"
        id="accountCreate.partialSuccessHeader.heading"
      />
    </H1>
  </HeaderCrateWithLogo>
);
