import { isNumericWithExactLength } from '../../../shared/app/utils/validators';

export const validateChecked = ({ checked }) => {
  return {
    error: !checked,
  };
};

export const validateSvcCardNumber = ({ value }) => {
  const isValid = isNumericWithExactLength(value, 16);
  return {
    error: !isValid,
    errorDetails: {
      cardNumber: !isValid,
    },
  };
};

export const validateSvcCardPin = ({ value }) => {
  const isValid = isNumericWithExactLength(value, 8);
  return {
    error: !isValid,
    errorDetails: {
      cardPin: !isValid,
    },
  };
};
