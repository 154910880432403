import { trackEvent, uoLoginUser } from 'shared/app/utils/uo-tracking';

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackAuthCreateAccountClick = () =>
  trackEvent('AUTH_CREATE_ACCOUNT_CLICK');

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackAuthCreateAccountError = () =>
  trackEvent('AUTH_CREATE_ACCOUNT_ERROR');

/**
 * @param {String} userExId User's exId
 * @returns {Promise<void>} The event has been sent.
 */
export const trackAuthCreateAccountSuccess = (userExId) => {
  return uoLoginUser(userExId).then(() => {
    trackEvent('AUTH_CREATE_ACCOUNT_SUCCESS');
    // allow some time to send events to mParticle before redirection.
    const delay = 200;
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  });
};
