import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Animator from '@starbucks-web/pattern-library/lib/components/animator';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import FormHeading from '@starbucks-web/pattern-library/lib/components/form-heading';

import { legacyBaseUrlSelector } from 'shared/app/state/selectors/locales';

import AccountCreate from './account-create';
import AccountCreateHeader from '../header';

import { commonMessages } from 'shared/app/messages';
import { messages } from '../../messages';

import styles from './styles.cssm';

const AccountCreateMain = ({ intl: { formatMessage }, legacyBaseUrl }) => (
  <Animator animation="fade">
    <ContentColumn className="pt4 md-pt7" key="headings" size="md">
      <AccountCreateHeader />
      <FormHeading
        className="md-text-center mdMax-hidden text-xxs pb3"
        tagName="h2"
      >
        <FormattedMessage {...commonMessages.starbucksRewardsTrademark} />
      </FormHeading>
      <p className="md-text-center pb5 md-pb7 text-xxs color-textBlackSoft md-px2">
        <FormattedMessage
          {...messages.rewardsInfo}
          values={{
            more: (
              <React.Fragment>
                <span className="hiddenVisually">
                  <FormattedMessage {...messages.plusMoreCTA} />
                </span>
                <Button
                  aria-label={formatMessage(messages.a11yContext)}
                  href={`${legacyBaseUrl}/rewards`}
                  visualStyle="textLink"
                >
                  <FormattedMessage {...messages.plusMoreCTA} />
                </Button>
              </React.Fragment>
            ),
          }}
        />
      </p>
    </ContentColumn>
    <ContentColumn className={`${styles.column} mb9`} key="content" size="md">
      <AccountCreate />
    </ContentColumn>
  </Animator>
);

const select = (state) => ({
  legacyBaseUrl: legacyBaseUrlSelector(state),
});

export default injectIntl(connect(select)(AccountCreateMain));
