import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import FirstLastNameFields from 'shared/app/components/first-last-name-fields';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import FormCard from '../form-card';

const messages = defineMessages({
  sectionHeading: {
    id: 'accountCreate.personalInformation.heading',
    defaultMessage: 'Personal Information',
    description:
      'Heading for personal information section of account create form',
  },
});

const PersonalInformation = ({ firstName, lastName }) => (
  <FormCard>
    <Heading className="mb2 text-semibold" size="sm" tagName="h2">
      <FormattedMessage {...messages.sectionHeading} />
    </Heading>
    <FirstLastNameFields firstName={firstName} lastName={lastName} />
  </FormCard>
);

PersonalInformation.propTypes = {
  firstName: PropTypes.object,
  lastName: PropTypes.object,
};

export default PersonalInformation;
