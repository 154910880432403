import config from 'config';

const topics = config.get('universal.communicationPreferences.topics');

export const SBUX_EMAIL_FIELD = 'receiveStarbucksEmailCommunications';
export const SBUX_GENERAL_EMAIL_FIELD = 'receiveGeneralEmailCommunications';
export const SBUX_PERSONALIZED_EMAIL_FIELD =
  'receivePersonalizedEmailCommunications';

export default (localeEmailTopics, checkGeneralEmailByDefault) => {
  const communicationPreferencesFields = {};

  if (!localeEmailTopics) {
    return communicationPreferencesFields;
  }

  const communicationPreferencesMap = {
    [topics.sbuxEmail]: {
      name: SBUX_EMAIL_FIELD,
      defaultValue: Boolean(checkGeneralEmailByDefault),
    },
    [topics.sbuxGeneralEmail]: {
      name: SBUX_GENERAL_EMAIL_FIELD,
      defaultValue: Boolean(checkGeneralEmailByDefault),
    },
    [topics.sbuxPersonalizedEmail]: {
      name: SBUX_PERSONALIZED_EMAIL_FIELD,
      defaultValue: false,
    },
  };

  localeEmailTopics.forEach((topic) => {
    const communicationField = communicationPreferencesMap[topic];
    return (
      communicationField &&
      Object.assign(communicationPreferencesFields, {
        [communicationField.name]: {
          required: false,
          input: {
            checked: communicationField.defaultValue,
          },
        },
      })
    );
  });
  return communicationPreferencesFields;
};
