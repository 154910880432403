import { createSelector } from 'reselect';
import { toMessageId } from '../../utils/map-error-message';
import { configSelector } from 'shared/app/shell';
import { routeParamsSelector } from 'shared/app/state/selectors/routes';

import {
  coreAppBaseUrlSelector,
  defaultSigninRedirectSelector,
} from 'shared/app/state/selectors/locales';

import getQueryFromWindow from 'shared/app/utils/get-query-from-window';

export const includePrivacyStatementSelector = createSelector(
  configSelector,
  (config) => config.includePrivacyStatement
);

export const includeStorageConsentSelector = createSelector(
  configSelector,
  (config) => config.includeStorageConsent
);

export const checkGeneralEmailByDefaultSelector = createSelector(
  configSelector,
  (config) => config.checkGeneralEmailByDefault
);

export const successRedirectUrlSelector = defaultSigninRedirectSelector;

export const cardRegistrationUrlSelector = createSelector(
  coreAppBaseUrlSelector,
  (coreAppBaseUrl) => `${coreAppBaseUrl}/account/cards`
);

export const cardErrorMessageIdSelector = createSelector(
  routeParamsSelector,
  (routeParams) => toMessageId(routeParams.message)
);

export const optInConfirmedSelector = createSelector(
  configSelector,
  (config) => config.optInConfirmed
);

export const optInMessageIdSelector = createSelector(
  optInConfirmedSelector,
  (confirmed) => {
    const query = getQueryFromWindow();
    if (!confirmed) {
      return 'error';
    }
    return Array.isArray(query.topic)
      ? 'sbux_gen+per_email'
      : query.topic.toLowerCase();
  }
);
