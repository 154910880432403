import { defineMessages } from 'react-intl';

export default defineMessages({
  termsOfUseError: {
    id: 'accountCreate.termsAndConditions.TOUError',
    defaultMessage: 'Please agree to the Terms of Use',
    description: 'Error message displayed when TOU is not checked',
  },
  storageConsentError: {
    id: 'accountCreate.termsAndConditions.storageConsentError',
    defaultMessage: 'Please agree to the storage conditions',
    description: 'Error message displayed when Storage consent is not checked',
  },
  rewardsTermsName: {
    id: 'accountCreate.termsAndConditions.rewardsTermsName',
    description: 'Name of the rewards terms linked from account create',
    defaultMessage: '{starbucksRewardsTrademark} Terms',
  },
  cardTermsName: {
    id: 'accountCreate.termsAndConditions.cardTermsName',
    description: 'Name of the card terms linked from account create',
    defaultMessage: 'Starbucks Card Terms',
  },
  privacyStatementName: {
    id: 'accountCreate.termsAndConditions.privacyStatementName',
    description: 'Name of the privacy statement linked from account create',
    defaultMessage: 'Starbucks Privacy Statement',
  },
  termsCheckboxLabel: {
    id: 'accountCreate.termsAndConditions.checkboxLabel',
    description: 'Label for account create terms of use checkbox',
    defaultMessage:
      'I agree to the {rewardsTerms} and the {cardTerms} and have read the {privacyStatement}.',
  },
  storageConsentMessage: {
    id: 'accountCreate.termsAndConditions.storageConsentMessage',
    description:
      'Message to inform user that their personal data will be stored in USA',
    defaultMessage:
      'By creating an account, I consent that my personal information will be stored and processed in the United States for the purposes of providing card services.',
  },
});
