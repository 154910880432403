import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Checkbox from '@starbucks-web/pattern-library/lib/components/checkbox';

import FormHeading from '@starbucks-web/pattern-library/lib/components/form-heading';
import ExternalLink from 'shared/app/components/external-link';
import { includePrivacyStatementSelector } from '../../state/selectors';
import {
  privacyStatementContactUsUrlSelector,
  privacyStatementUrlSelector,
} from 'shared/app/state/selectors/privacy-and-terms';
import FormCard from '../form-card';
import messages from './messages';

const MailingList = ({
  includePrivacyStatement,
  receiveGeneralEmailCommunications,
  receivePersonalizedEmailCommunications,
  receiveStarbucksEmailCommunications,
  privacyStatementContactUsUrl,
  privacyStatementUrl,
}) => {
  const { formatMessage } = useIntl();

  const privacyNoticeLink = (
    <ExternalLink href={privacyStatementUrl}>
      {formatMessage(messages.privacyNoticeLinkText)}
    </ExternalLink>
  );

  const addGeneralEmailCommunication = (communication) => (
    <Checkbox {...communication.input} data-e2e="receiveEmail">
      <FormattedMessage {...messages.mailingListLabel} />
      <span className="inline-block mt3 color-textBlackSoft">
        <FormattedMessage
          {...messages.mailingListDescription}
          values={{ privacyNoticeLink }}
        />
      </span>
    </Checkbox>
  );

  return (
    <React.Fragment>
      <FormCard>
        <FormHeading className="mb4">
          <FormattedMessage {...messages.cardTitle} />
        </FormHeading>
        <p className="mb3">
          <FormattedMessage {...messages.cardDescription} />
        </p>
        {receiveStarbucksEmailCommunications &&
          addGeneralEmailCommunication(receiveStarbucksEmailCommunications)}
        {receiveGeneralEmailCommunications &&
          addGeneralEmailCommunication(receiveGeneralEmailCommunications)}
        {receivePersonalizedEmailCommunications && (
          <Checkbox
            {...receivePersonalizedEmailCommunications.input}
            data-e2e="receivePersonalizedEmail"
          >
            <FormattedMessage {...messages.personalizedEmailLabel} />
          </Checkbox>
        )}
        {includePrivacyStatement && (
          <p className="mt3">
            <FormattedMessage
              {...messages.privacyStatement}
              values={{
                privacyStatementName: (
                  <ExternalLink href={privacyStatementUrl}>
                    {formatMessage(messages.privacyStatementName)}
                  </ExternalLink>
                ),
                privacyStatementContactUsName: (
                  <ExternalLink href={privacyStatementContactUsUrl}>
                    {formatMessage(messages.privacyStatementContactUsName)}
                  </ExternalLink>
                ),
              }}
            />
          </p>
        )}
      </FormCard>
    </React.Fragment>
  );
};

MailingList.propTypes = {
  receiveGeneralEmailCommunications: PropTypes.object,
  receivePersonalizedEmailCommunications: PropTypes.object,
  receiveStarbucksEmailCommunications: PropTypes.object,
};

const select = (state) => ({
  includePrivacyStatement: includePrivacyStatementSelector(state),
  privacyStatementContactUsUrl: privacyStatementContactUsUrlSelector(state),
  privacyStatementUrl: privacyStatementUrlSelector(state),
});

export default connect(select)(MailingList);
