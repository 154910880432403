import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Field from '@starbucks-web/pattern-library/lib/components/field';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';

import { messages } from './register-card-fields-messages';
import { formMessages } from 'shared/app/messages/user-form-input';

const RegisterCardFields = ({ registerCardNumber, registerCardPin }) => {
  const { formatMessage } = useIntl();

  const cardNumberError = shouldDisplayError(registerCardNumber);
  const cardPinError = shouldDisplayError(registerCardPin);

  // Use `type='tel'` to make Android use numeric keyboard without trappings of `type='number'`
  const inputType = 'tel';

  return (
    // left padding equivalent to size of radio icon plus left margin of radio label
    <div className="pr1 pt2" style={{ paddingLeft: '44px' }}>
      <Field
        className="mb2"
        id={registerCardNumber?.input?.id ?? 'registerCardNumber'}
        maxLength={16}
        maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
        pattern="[0-9]*"
        {...registerCardNumber.input}
        error={cardNumberError}
        errorMessage={
          registerCardNumber.errorMessage ||
          formatMessage(messages.cardNumberError)
        }
        hintText={formatMessage(messages.cardNumberInstructions)}
        type={inputType}
        value={registerCardNumber?.input?.value ?? ''}
      >
        {formatMessage(messages.cardNumberLabel)}
      </Field>

      <Field
        id={registerCardPin?.input?.id ?? 'registerCardPin'}
        maxLength={8}
        maxLengthAriaMessage={formatMessage(formMessages.maxLengthAriaMessage)}
        pattern="[0-9]*"
        {...registerCardPin.input}
        error={cardPinError}
        errorMessage={
          registerCardPin.errorMessage || formatMessage(messages.cardPinError)
        }
        hintText={formatMessage(messages.cardPinInstructions)}
        type={inputType}
        value={registerCardPin?.input?.value ?? ''}
      >
        {formatMessage(messages.cardPinLabel)}
      </Field>
    </div>
  );
};

RegisterCardFields.propTypes = {
  registerCardNumber: PropTypes.object.isRequired,
  registerCardPin: PropTypes.object.isRequired,
};

export default RegisterCardFields;
