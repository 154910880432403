import { defineMessages } from 'react-intl';

export default defineMessages({
  createAccountStandardHeader: {
    id: 'accountCreate.createAccount',
    defaultMessage: 'Create an account',
    description: 'Primary title for account create page',
  },
  createAccountPromoHeader: {
    id: 'accountCreate.createAccount.promo',
    defaultMessage: 'Create an account to redeem the offer',
    description: 'Primary heading for account create page for redeeming promo',
  },
  createAccountDeltaAirlinesHeader: {
    id: 'accountCreate.createAccount.deltaAirlinesHeader',
    defaultMessage:
      'Create a Starbucks Rewards account to finish linking your Delta account',
    description: 'Primary heading for account create page for Delta Airlines',
  },
  createAccountMarriottHeader: {
    id: 'accountCreate.createAccount.marriottHeader',
    defaultMessage:
      'Create a Starbucks® Rewards account to link your Marriott Bonvoy® account',
    description: 'Primary heading for account create page for Marriott Bonvoy',
  },
});
