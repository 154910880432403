import PropTypes from 'prop-types';
import React from 'react';

import CardRewardsExpander from './card-rewards-expander';

const CardRewards = ({
  registerCardNumber,
  registerCardPin,
  cardRewardsProps: { physicalSvcFieldsExpanded, toggleCardRewardsExpander },
}) => {
  return (
    <CardRewardsExpander
      data-e2e="cardRewardsExpander"
      physicalSvcFieldsExpanded={physicalSvcFieldsExpanded}
      registerCardNumber={registerCardNumber}
      registerCardPin={registerCardPin}
      toggleCardRewardsExpander={toggleCardRewardsExpander}
    />
  );
};

CardRewards.propTypes = {
  cardRewardsProps: PropTypes.shape({
    physicalSvcFieldsExpanded: PropTypes.bool,
    toggleCardRewardsExpander: PropTypes.elementType,
  }),
  registerCardNumber: PropTypes.object,
  registerCardPin: PropTypes.object,
};

CardRewards.defaultProps = {
  registerCardNumber: {},
  registerCardPin: {},
};

export default CardRewards;
