import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Expander from '@starbucks-web/pattern-library/lib/components/expander';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import caretDown from '@starbucks-web/pattern-library/lib/icons/caret-down';
import FormCard from '../form-card';
import RegisterCardFields from './register-card-fields';

import messages from './messages';
import styles from './styles.cssm';

const CardRewardsExpander = ({
  physicalSvcFieldsExpanded,
  toggleCardRewardsExpander,
  ...registerCardProps
}) => {
  return (
    <FormCard>
      <Button
        aria-controls="card-rewards-expander"
        aria-expanded={physicalSvcFieldsExpanded}
        className="color-greenStarbucks text-bold text-xs"
        data-e2e="cardRewardsExpanderButton"
        onClick={() => toggleCardRewardsExpander()}
        visualStyle="textLink"
      >
        <FormattedMessage {...messages.expanderLabel} />
        <Icon
          className={
            physicalSvcFieldsExpanded ? styles.openDropdown : styles.dropdown
          }
          path={caretDown}
          size="20px"
        />
      </Button>
      <Expander
        id="card-rewards-expander"
        isExpanded={Boolean(physicalSvcFieldsExpanded)}
      >
        <p className="pt2">
          <FormattedMessage {...messages.expanderInstructions} />
        </p>
        <RegisterCardFields {...registerCardProps} />
      </Expander>
    </FormCard>
  );
};

CardRewardsExpander.propTypes = {
  physicalSvcFieldsExpanded: PropTypes.bool,
  toggleCardRewardsExpander: PropTypes.elementType,
};

export default CardRewardsExpander;
