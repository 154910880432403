import getReturnUrlFromWindow from 'shared/app/utils/get-return-url-from-window';

export const getRedirectUrl = (successRedirectUrl) => {
  const returnUrl = getReturnUrlFromWindow();

  if (returnUrl) {
    return returnUrl;
  }

  return successRedirectUrl;
};

export default getRedirectUrl;
